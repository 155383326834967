import * as requestFromServer from "./transactionsCrud";
import { transactionsSlice, callTypes } from "./transactionsSlice";
import * as auth from "../../../Auth/_redux/authRedux";

const { actions } = transactionsSlice;


export const fetchTransactions = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findTransactions(queryParams)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { totalCount, entities } = response.data;
      dispatch(actions.transactionsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find transactions";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};


//fetchPendingTransactions
export const fetchPendingTransactions = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findPendingTransactions(queryParams)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { totalCount, entities } = response.data;
      dispatch(actions.transactionsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find pending transactions";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//fetch pending confirmation transactions
export const fetchPendingConfirmationTransactions = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findPendingConfirmationTransactions(queryParams)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { totalCount, entities } = response.data;
      dispatch(actions.transactionsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find pending confirmation transactions";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//fetch pending deposit transactions
export const fetchPendingDepositTransactions = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findPendingDepositTransactions(queryParams)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { totalCount, entities } = response.data;
      dispatch(actions.transactionsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find pending deposit transactions";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//fetch cancelled transactions
export const fetchCancelledTransactions = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findCancelledTransactions(queryParams)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { totalCount, entities } = response.data;
      dispatch(actions.transactionsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find cancelled transactions";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//fetch processing deposit transactions
export const fetchProcessingDepositTransactions = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findProcessingDepositTransactions(queryParams)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { totalCount, entities } = response.data;
      dispatch(actions.transactionsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find processing deposit transactions";
      dispatch(actions.catchError({ error, callType: callTypes.list }));  
    });
};


//fetch completed transactions
export const fetchCompletedTransactions = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findCompletedTransactions(queryParams)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { totalCount, entities } = response.data;
      dispatch(actions.transactionsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find completed transactions";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//fetch failed  transactions
export const fetchFailedTransactions = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findFailedTransactions(queryParams)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { totalCount, entities } = response.data;
      dispatch(actions.transactionsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find failed transactions";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

//make mpesa  deposit
export const makeMpesaDeposit = transaction => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .makeMpesaDeposit(transaction)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.transactionUpdated({ transaction }));
    })
    .catch(error => {
      error.clientMessage = "Can't make deposit";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

//retry mpesa deposit
export const retryMpesaDeposit = transaction => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .retryMpesaDeposit(transaction)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.transactionUpdated({ transaction }));
    })
    .catch(error => {
      error.clientMessage = "Can't retry deposit";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

//make coop deposit
export const makeCoopDeposit = transaction => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .makeCoopDeposit(transaction)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.transactionUpdated({ transaction }));
    })
    .catch(error => {
      error.clientMessage = "Can't make deposit";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

//retry coop deposit
export const retryCoopDeposit = transaction => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .retryCoopDeposit(transaction)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.transactionUpdated({ transaction }));
    })
    .catch(error => {
      error.clientMessage = "Can't retry deposit";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

//make kcb deposit
export const makeKcbDeposit = transaction => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .makeKcbDeposit(transaction)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.transactionUpdated({ transaction }));
    })
    .catch(error => {
      error.clientMessage = "Can't make deposit";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

//retry kcb deposit
export const retryKcbDeposit = transaction => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .retryKcbDeposit(transaction)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.transactionUpdated({ transaction }));
    })
    .catch(error => {
      error.clientMessage = "Can't retry deposit";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// //transactionsByCountryReport
// router.post('/api/transactions/country-report', [authenticateToken], transactionsController.transactionsByCountryReport);
// //transactionsByCurrencyReport
// router.post('/api/transactions/currency-report', [authenticateToken], transactionsController.transactionsByCurrencyReport);
// //transactionsByStatusReport
// router.post('/api/transactions/status-report', [authenticateToken], transactionsController.transactionsByStatusReport);
// //transactionsReport
// router.post('/api/transactions/transactions-report', [authenticateToken], transactionsController.transactionsReport);
// //feesReport
// router.post('/api/transactions/fees-report', [authenticateToken], transactionsController.feesReport);


//totalDisbursedAmountReportFetched - totalDisbursedAmount
//transactionsByCountryReportFetched - transactionsByCountry
//transactionsByCurrencyReportFetched - transactionsByCurrency
//transactionsByStatusReportFetched - transactionsByStatus
//transactionsReportFetched - totalTransactions
//totalFeesFetched - totalFees





export const totalDisbursedAmountReport = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .totalDisbursedAmountReport()
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.totalDisbursedAmountReportFetched({ totalDisbursedAmount: response.data.result.totalDisbursedAmount }));
    })
    .catch(error => {
      error.clientMessage = "Can't find total disbursed amount";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

//transactionsByCountryReport
export const transactionsByCountryReport = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .transactionsByCountryReport()
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { transactionsByCountry } = response.data.result;
      dispatch(actions.transactionsByCountryReportFetched({ transactionsByCountry }));
    })
    .catch(error => {
      error.clientMessage = "Can't find transactions by country";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

//transactionsByCurrencyReport
export const transactionsByCurrencyReport = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .transactionsByCurrencyReport()
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { transactionsByCurrency } = response.data.result;
      dispatch(actions.transactionsByCurrencyReportFetched({ transactionsByCurrency }));
    })
    .catch(error => {
      error.clientMessage = "Can't find transactions by currency";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

//transactionsByStatusReport //transactionsByStatus
export const transactionsByStatusReport = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .transactionsByStatusReport()
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { transactionsByStatus } = response.data.result;
      dispatch(actions.transactionsByStatusReportFetched({ transactionsByStatus }));
    })
    .catch(error => {
      error.clientMessage = "Can't find transactions by status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

//transactionsReport
export const transactionsReport = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .transactionsReport()
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { totalTransactions } = response.data.result;
      dispatch(actions.transactionsReportFetched({ totalTransactions }));
    })
    .catch(error => {
      error.clientMessage = "Can't find transactions report";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

//downloadCompletedReport
export const downloadCompletedReport = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .downloadCompletedReport()
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { downloadCompletedReport } = response.data.result;
      dispatch(actions.downloadCompletedReportFetched({ downloadCompletedReport }));
    })
    .catch(error => {
      error.clientMessage = "Can't download completed report";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

//uniqueSendersReport
export const fetchUniqueSenders = search => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .fetchUniqueSenders(search)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const  uniqueSenders  = response.data.result.uniqueSenders;
      dispatch(actions.uniqueSendersFetched({ uniqueSenders }));
    })
    .catch(error => {
      error.clientMessage = "Can't find unique senders report";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

//feesReport
export const feesReport = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .feesReport()
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const  totalFees  = response.data.result.totalFees;
      dispatch(actions.totalFeesFetched({ totalFees }));
    })
    .catch(error => {
      error.clientMessage = "Can't find fees report";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

//monthReport
export const monthReport = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .monthReport()
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const  monthReport  = response.data.result.transactionsByMonth;
      dispatch(actions.monthReportFetched({ monthReport }));
    })
    .catch(error => {
      error.clientMessage = "Can't find month report";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

//dayReport
export const dayReport = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .dayReport()
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const  dayReport  = response.data.result.dayReport;
      dispatch(actions.dayReportFetched({ dayReport }));
    })
    .catch(error => {
      error.clientMessage = "Can't find day report";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};



export const fetchTransaction = id => dispatch => {
  if (!id) {
    return dispatch(actions.transactionFetched({ transactionForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getTransactionById(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const transaction = response.data.transaction;
      dispatch(actions.transactionFetched({ transactionForEdit: transaction }));
    })
    .catch(error => {
      error.clientMessage = "Can't find transaction";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteTransaction = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteTransaction(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.transactionDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete transaction";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createTransaction = transactionForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createTransaction(transactionForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { transaction } = response.data;
      dispatch(actions.transactionCreated({ transaction }));
    })
    .catch(error => {
      error.clientMessage = "Can't create transaction";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateTransaction = transaction => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateTransaction(transaction)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.transactionUpdated({ transaction }));
    })
    .catch(error => {
      error.clientMessage = "Can't update transaction";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateTransactionsStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForTransactions(ids, status)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.transactionsStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update transactions status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteTransactions = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteTransactions(ids)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.transactionsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete transactions";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};