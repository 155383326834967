export const appMenus = [
    {
        title: "Transfers",
        icon: "/media/svg/icons/Files/Selected-file.svg",
        page: "/transfers",
        tab: "tabTransfers",
        permission: "",
        section: [
            {
                title: "Money Transfer",
                permission: "",
                submenu: [
                    {
                        title: "Dashboard",
                        icon: "/media/svg/icons/Files/Downloads-folder.svg",
                        page: "/dashboard",
                        permission: "",
                    },
                    {
                        title: "Add New Transaction",
                        icon: "/media/svg/icons/Files/Downloads-folder.svg",
                        page: "/transaction-management/transactions/new",
                        permission: "accessToTransactionsCreate",
                    },
                    {
                        title: "Transactions Pending Approval",
                        icon: "/media/svg/icons/Files/Downloads-folder.svg",
                        page: "/transaction-management/transactions/pending-transactions",
                        permission: "accessToTransactionsApprove",
                    },
                    {
                        title: "Transactions Pending Confirmation",
                        icon: "/media/svg/icons/Files/Downloads-folder.svg",
                        page: "/transaction-management/transactions/pending-confirmation",
                        permission: "accessToTransactionsConfirm",
                    },
                    {
                        title: "Transactions Pending Deposit",
                        icon: "/media/svg/icons/Files/Downloads-folder.svg",
                        page: "/transaction-management/transactions/pending-deposit",
                        permission: "accessToTransactionsDeposit",
                    },
                    {
                        title: "Cancelled Transactions",
                        icon: "/media/svg/icons/Files/Downloads-folder.svg",
                        page: "/transaction-management/transactions/cancelled-transactions",
                        permission: "",
                    },
                    {
                        title: "Transactions Processing Deposit",
                        icon: "/media/svg/icons/Files/Downloads-folder.svg",
                        page: "/transaction-management/transactions/processing-deposit",
                        permission: "accessToTransactionsDeposit",
                    },
                    {
                        title: "Completed Transactions",
                        icon: "/media/svg/icons/Files/Downloads-folder.svg",
                        page: "/transaction-management/transactions/completed-transactions",
                        permission: "",
                    },
                    {
                        title: "Failed Transactions",
                        icon: "/media/svg/icons/Files/Downloads-folder.svg",
                        page: "/transaction-management/transactions/failed-deposit",
                        permission: "accessToTransactionsRetry",
                    },
                    {
                        title: "All Transactions",
                        icon: "/media/svg/icons/Files/Downloads-folder.svg",
                        page: "/transaction-management",
                        permission: "",
                    },
                ],
            },
            {
                title: "Manage Agents",
                permission: "accessTOAgentManagementRead",
                submenu: [
                    {
                        title: "Add New Agent",
                        icon: "/media/svg/icons/Files/Folder-cloud.svg",
                        page: "/agents/add-new-agent",
                        permission: "accessTOAgentManagementRead",
                    },
                    {
                        title: "List of Agents",
                        icon: "/media/svg/icons/Files/Folder-cloud.svg",
                        page: "/agents/list-of-agents",
                        permission: "accessTOAgentManagementRead",
                    },
                    {
                        title: "My Agents",
                        icon: "/media/svg/icons/Files/Folder-cloud.svg",
                        page: "/agents/my-agents",
                        permission: "accessTOAgentManagementRead",
                    },
                ],
            },
            {
                title: "Reports",
                permission: "accessToReportsAdminRead",
                submenu: [
                    {
                        title: "Transactions",
                        icon: "/media/svg/icons/Files/Folder-cloud.svg",
                        page: "/reports/transactions",
                        permission: "accessToReportsAdminRead",
                    },
                    {
                        title: "Agents",
                        icon: "/media/svg/icons/Files/Folder-cloud.svg",
                        page: "/reports/agents",
                        permission: "accessToReportsAdminRead",
                    },
                    {
                        title: "Users",
                        icon: "/media/svg/icons/Files/Folder-cloud.svg",
                        page: "/reports/users",
                        permission: "accessToReportsAdminRead",
                    },
                ],
            },
        ],
    },
    {
        title: "User Management",
        icon: "/media/svg/icons/Communication/Group.svg",
        page: "/user-management",
        tab: "tabUsers",
        permission: "accessToUsersRead",
        section: [
            {
                title: "User Management",
                permission: "accessToUsersRead",
                submenu: [
                    {
                        title: "Manage Users",
                        icon: "/media/svg/icons/Communication/Group.svg",
                        page: "/user-management/users",
                        permission: "accessToUsersRead",
                    },
                    {
                        title: "Manage Roles",
                        icon: "/media/svg/icons/Code/Compiling.svg",
                        page: "/user-management/roles",
                        permission: "accessToRolesRead",
                    },
                ],
            },
        ],
    },
    {
        title: "Settings",
        icon: "/media/svg/icons/General/Settings-1.svg",
        page: "/settings",
        tab: "tabSettings",
        permission: "accessToSettingsRead",
        section : [
            {
                title: "Settings",
                permission: "accessToSettingsRead",
                submenu: [
                    {
                        title: "View Settings",
                        icon: "/media/svg/icons/General/Settings-1.svg",
                        page: "/settings/view",
                        permission: "accessToSettingsRead",
                    },
                    {
                        title: "Update Settings",
                        icon: "/media/svg/icons/General/Settings-1.svg",
                        page: "/settings/update",
                        permission: "accessToSettingsRead",
                    },
                ],
            },
            {
                title: "Conversion Rates",
                permission: "accessToSettingsRead",
                submenu: [
                    {
                        title: "Manage Conversion Rates",
                        icon: "/media/svg/icons/General/Settings-1.svg",
                        page: "/conversion-rate/conversion-rate",
                        permission: "accessToSettingsRead",
                    },
                ],
            },
            {
                title: "Manage Customers",
                permission: "accessToSettingsRead",
                submenu: [
                    {
                        title: "Manage Customers",
                        icon: "/media/svg/icons/General/Settings-1.svg",
                        page: "/customer-management/customers",
                        permission: "accessToSettingsRead",
                    },
                ],
            },
        ],
    }
];