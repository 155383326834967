import React, { useMemo } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { appConfigs } from "../../../../_money_transfer/_helpers";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

export function Footer() {
  const uiService = useHtmlClassService();
  const current_year = new Date().getFullYear();

  const layoutProps = useMemo(() => {
    return {
      footerLayout: objectPath.get(uiService.config, "footer.layout"),
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true),
    };
  }, [uiService]);

  return (
    <>
      {/* begin::Footer */}
      {/* doc: add "bg-white" class to have footer with solod background color */}
      <div
        className={`footer py-4 d-flex flex-lg-column ${layoutProps.footerClasses}`}
        id="kt_footer"
      >
        {/* begin::Container */}
        <div
          className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
        >
          {/* begin::Copyright */}
          <div className="text-dark order-2 order-md-1">
            <span className="text-muted font-weight-bold mr-">
              {current_year} &copy;
            </span>
            {` `}
            <a
              href={appConfigs.org_url}
              rel="noopener noreferrer"
              target="_blank"
              className="text-dark-75 text-hover-primary"
            >
              {appConfigs.org_name}
            </a>
          </div>
          {/* end::Copyright */}
          {` `}
          {/* begin::Nav */}
          <div className="nav nav-dark order-1 order-md-2">
            <Link to="/privacy" className="nav-link pr-3 pl-0"><FormattedMessage id="AUTH.FOOTER.LINKS.PRIVACY" /></Link>
            <Link to="/contact" className="nav-link pr-3 pl-0"><FormattedMessage id="AUTH.FOOTER.LINKS.CONTACT" /></Link>
          </div>
          {/* end::Nav */}
        </div>
        {/* end::Container */}
      </div>
      {/* end::Footer */}
    </>
  );
}
