export const appConfigs = {
    app_name: "Bonobo fx",
    org_name: process.env.NODE_ENV !== "production" ? "Bonobo fx (Dev)" : "Bonobo fx",
    org_url: "https://money-transfer.africa",
    api_url: process.env.NODE_ENV !== "production" ? "http://localhost:8877/api/" : (window.location.host === "money-transfer.sandbox.co.ke" ? "https://money-transfer.sandbox.co.ke:8187/api/" : "https://money-transfer.africa:8187/api/"),
    portal_api_url: "https://money-transfer.sandbox.coo.ke:8443/api/",
    
    auth_key: process.env.NODE_ENV !== "production" ? "XNAgZSnoEsf55nlbmDIJ" : "rK32640byHUTixKE7ge1",
    uploads_url: process.env.NODE_ENV !== "production" ? "http://localhost:8877/u0veDcqIsErQVKXuXeM3/" : (window.location.host === "money-transfer.sandbox.co.ke" ? "https://money-transfer.sandbox.co.ke:8187/u0veDcqIsErQVKXuXeM3/" : "https://money-transfer.africa:8187/u0veDcqIsErQVKXuXeM3/"),
    recaptcha_site_key: "6LfdYeQZAAAAAPijoDtW-p4RLdUZBnc0brmarW5M",
};