import {createSlice} from "@reduxjs/toolkit";


const initialTransactionsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  transactionForEdit: undefined,
  lastError: null,
  totalFees: 0,
  totalDisbursedAmount: 0,
  transactionsByCountry: [],
  transactionsByCurrency: [],
  transactionsByStatus: [],
  totalTransactions: 0,
  uniqueSenders:[],
  monthReport:[],
  dayReport:[],
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const transactionsSlice = createSlice({
  name: "transactions",
  initialState: initialTransactionsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getTransactionById
    transactionFetched: (state, action) => {
      state.actionsLoading = false;
      state.transactionForEdit = action.payload.transactionForEdit;
      state.error = null;
    },
    // findTransactions
    transactionsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createTransaction
    transactionCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      if(action.payload.transaction !== null){
        var usr = action.payload.transaction;
        usr.id = state.entities.length + 1;
        usr.gender = (usr.gender === 1) ? "Male" : (usr.gender === 0) ? "Female" : "Unspecified";
        state.entities.push(usr);
      }
    },
    // updateTransaction
    transactionUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.transaction.id) {
          return action.payload.transaction;
        }
        return entity;
      });
    },
    // deleteTransaction
    transactionDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteTransactions
    transactionsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // transactionsUpdateState
    transactionsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    //totalDisbursedAmountReportFetched
    totalDisbursedAmountReportFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.totalDisbursedAmount = action.payload.totalDisbursedAmount;
    },
    //transactionsByCountryReportFetched
    transactionsByCountryReportFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.transactionsByCountry = action.payload.transactionsByCountry;
    },
    //transactionsByCurrencyReportFetched
    transactionsByCurrencyReportFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.transactionsByCurrency = action.payload.transactionsByCurrency;
    },
    //transactionsByStatusReportFetched
    transactionsByStatusReportFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.transactionsByStatus = action.payload.transactionsByStatus;
    },
    //transactionsReportFetched
    transactionsReportFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.totalTransactions = action.payload.totalTransactions;
    },
    //feesReportFetched
    totalFeesFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.totalFees = action.payload.totalFees;
    },
    //downloadCompletedReportFetched
    downloadCompletedReportFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.downloadCompletedReport = action.payload.downloadCompletedReport;
    },
    //uniqueSendersFetched
    uniqueSendersFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.uniqueSenders = action.payload.uniqueSenders;
    },
    //monthReportFetched
    monthReportFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.monthReport = action.payload.monthReport;
    },
    //dayReportFetched
    dayReportFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.dayReport = action.payload.dayReport;
    },
  }
});
