import React, { useEffect } from "react";//
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import * as actions from "../modules/Transfers/_redux/transactions/transactionsActions";
import { Alert } from "react-bootstrap";
import {TilesWidget11,CustomBarChart } from "../../_money_transfer/_partials/widgets";

export function DashboardPage() {
    

    const { user_id,first_name,totalDisbursedAmount,totalFees,totalTransactions,transactionsByCountry,transactionsByCurrency,transactionsByStatus,monthReport,dayReport } = useSelector(
        (state) => ({
            user_id: state.auth.user.id || "",
            first_name: state.auth.user.first_name || "",
            totalDisbursedAmount: state.transactions.totalDisbursedAmount || 0,
            totalFees: state.transactions.totalFees || 0,
            totalTransactions: state.transactions.totalTransactions || 0,
            transactionsByCountry: state.transactions.transactionsByCountry || [],
            transactionsByCurrency: state.transactions.transactionsByCurrency || [],
            transactionsByStatus: state.transactions.transactionsByStatus || [],
            monthReport: state.transactions.monthReport || [],
            dayReport: state.transactions.dayReport || [],          


        }),
        shallowEqual
    );

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.totalDisbursedAmountReport({}));
        dispatch(actions.transactionsByCountryReport({}));
        dispatch(actions.transactionsByCurrencyReport({}));
        dispatch(actions.transactionsByStatusReport({}));
        dispatch(actions.transactionsReport({}));
        dispatch(actions.feesReport({}));
        dispatch(actions.monthReport({}));
        dispatch(actions.dayReport({}));

        // eslint-disable-next-line 
    }, [dispatch, user_id]);

    //function to display a HTML grid of 3 by two

    const TransactionStatusTitles = ["Draft", "Pending Approval","Pending Confirmation", "Pending Deposit","Cancelled", "Processing Deposit", "Completed", "Failed"];

    const grid = (widget1, widget2, widget3, widget4, widget5, widget6) => {
        return (
            <div className="row">
                <div className="col-xl-4">
                    {widget1}
                </div>
                <div className="col-xl-4">
                    {widget2}
                </div>
                <div className="col-xl-4">
                    {widget3}
                </div>
                <div className="col-xl-4">
                    {widget4}
                </div>
                <div className="col-xl-4">
                    {widget5}
                </div>
                <div className="col-xl-4">
                    {widget6}
                </div>
            </div>
        )
    }


    return (<>
        <div className="row">
            <div className="col-xl-12">
                <div className="card card-custom gutter-b card-stretch">
                    <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                            <h2>Bonobo FX</h2>
                            <Alert variant="primary"> Welcome {first_name}!</Alert>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-6">
                <div className="card card-custom gutter-b card-stretch">
                    <div className="card-header">
                        <h3 className="card-title">Transactions by Month</h3>
                    </div>
                    <div className="card-body">
                        <CustomBarChart chartData={monthReport} dataKey="total" dataName="Total" />
                    </div>
                </div>
            </div>
            <div className="col-xl-6">
                <div className="card card-custom gutter-b card-stretch">
                    <div className="card-header">
                        <h3 className="card-title">Transactions last 30 Days</h3>
                    </div>
                    <div className="card-body">
                        <CustomBarChart chartData={dayReport} dataKey="total" dataName="Total" />
                    </div>
                </div>
            </div>
            {grid(
                <TilesWidget11 className="card-stretch gutter-b" baseColor="primary" value={totalTransactions} label="Total Transactions" />,
                <TilesWidget11 className="card-stretch gutter-b" baseColor="info" value={totalDisbursedAmount.toFixed(2)} label="Total Disbursed amount" />,
                <TilesWidget11 className="card-stretch gutter-b" baseColor="success" value={totalFees.toFixed(2)} label="Total Fees Earned" />,
                <div className="card card-custom gutter-b">
                    <div className="card-header">
                        <h3 className="card-title">Transactions by Country</h3>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-borderless table-vertical-center">
                                <thead>
                                    <tr>
                                        <th className="p-0 w-50px">No</th>
                                        <th className="p-0 min-w-150px">Country</th>
                                        <th className="p-0 min-w-140px">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transactionsByCountry&&transactionsByCountry.length>0&&transactionsByCountry.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.country}</td>
                                                    <td>{item.total}</td>
                                                </tr>
                                            );
                                        }
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>,
                <div className="card card-custom gutter-b">
                    <div className="card-header">
                        <h3 className="card-title">Transactions by Currency</h3>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-borderless table-vertical-center">
                                <thead>
                                    <tr>
                                        <th className="p-0 w-50px">No</th>
                                        <th className="p-0 min-w-150px">Currency</th>
                                        <th className="p-0 min-w-140px">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transactionsByCurrency&&transactionsByCurrency.length>0&&transactionsByCurrency.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.currency}</td>
                                                <td>{item.total}</td>
                                            </tr>
                                        );
                                    }
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>,
                <div className="card card-custom gutter-b">
                    <div className="card-header">
                        <h3 className="card-title">Transactions by Status</h3>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-borderless table-vertical-center">
                                <thead>
                                    <tr>
                                        <th className="p-0 w-50px">No</th>
                                        <th className="p-0 min-w-150px">Status</th>
                                        <th className="p-0 min-w-140px">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transactionsByStatus&&transactionsByStatus.length>0&&transactionsByStatus.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{TransactionStatusTitles[parseInt(item.status)]}</td>
                                                    <td>{item.total}</td>
                                                </tr>
                                            );
                                        }
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> 
            )}
        </div>
    </>);
}
