import axios from "axios";
import { appConfigs } from "../../../../../_money_transfer/_helpers";

// CREATE =>  POST: add a new transaction to the server
export function createTransaction(transaction) {
  return axios.post(appConfigs.api_url + 'transactions/create', { transaction });
}

// READ
export function getAllTransactions() {
  return axios.post(appConfigs.api_url + 'transactions');
}

export function getTransactionById(transactionId) {
  return axios.post(`${appConfigs.api_url}transactions/get`, { id: transactionId })
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findTransactions(queryParams) {
  return axios.post(`${appConfigs.api_url}transactions`, { queryParams });
}

//findPendingTransactions
export function findPendingTransactions(queryParams) {
  return axios.post(`${appConfigs.api_url}transactions/pending`, { queryParams });
}

//findPendingConfirmationTransactions
export function findPendingConfirmationTransactions(queryParams) {
  return axios.post(`${appConfigs.api_url}transactions/pending-confirmation`, { queryParams });
}

//findPendingDepositTransactions
export function findPendingDepositTransactions(queryParams) {
  return axios.post(`${appConfigs.api_url}transactions/pending-deposit`, { queryParams });
}

//find cancelled transactions
export function findCancelledTransactions(queryParams) {
  return axios.post(`${appConfigs.api_url}transactions/cancelled`, { queryParams });
}

//find processing deposit transactions
export function findProcessingDepositTransactions(queryParams) {
  return axios.post(`${appConfigs.api_url}transactions/processing-deposit`, { queryParams });
}

//find completed transactions
export function findCompletedTransactions(queryParams) {
  return axios.post(`${appConfigs.api_url}transactions/completed`, { queryParams });
}

//transactions/unique-senders-report
export function fetchUniqueSenders(search){
  return axios.post(`${appConfigs.api_url}transactions/unique-senders-report`, { search });
}

//find failed transactions
export function findFailedTransactions(queryParams) {
  return axios.post(`${appConfigs.api_url}transactions/failed`, { queryParams });
}

//make mpesa deposit
export function makeMpesaDeposit(transaction) {
  return axios.post(`${appConfigs.api_url}transactions/make-mpesa-deposit`, { transaction });
}

//retry mpesa deposit
export function retryMpesaDeposit(transaction) {
  return axios.post(`${appConfigs.api_url}transactions/retry-deposit`, { transaction });
}

//make coop deposit
export function makeCoopDeposit(transaction) {
  return axios.post(`${appConfigs.api_url}transactions/make-coop-deposit`, { transaction });
}

//retry coop deposit
export function retryCoopDeposit(transaction) {
  return axios.post(`${appConfigs.api_url}transactions/retry-coop-deposit`, { transaction });
}

//make kcb deposit
export function makeKcbDeposit(transaction) {
  return axios.post(`${appConfigs.api_url}transactions/make-kcb-deposit`, { transaction });
}

//retry kcb deposit
export function retryKcbDeposit(transaction) {
  return axios.post(`${appConfigs.api_url}transactions/retry-kcb-deposit`, { transaction });
}

//transactions/download-completed-report
export function downloadCompletedReport(){
  return axios.post(`${appConfigs.api_url}transactions/download-completed-report`);
}

//transactions/month-report
export function monthReport(){
  return axios.post(`${appConfigs.api_url}transactions/month-report`);
}

//transactions/day-report
export function dayReport(){
  return axios.post(`${appConfigs.api_url}transactions/day-report`);
}


// //totalDisbursedAmountReport
// router.post('/api/transactions/total-disbursed-amount', [authenticateToken], transactionsController.totalDisbursedAmountReport);


//total disbursements
export function totalDisbursedAmountReport(){
  return axios.post(`${appConfigs.api_url}transactions/total-disbursed-amount`);
}

//transactionsByCountryReport
export function transactionsByCountryReport(){
  return axios.post(`${appConfigs.api_url}transactions/country-report`);
}

//transactionsByCurrencyReport
export function transactionsByCurrencyReport(){
  return axios.post(`${appConfigs.api_url}transactions/currency-report`);
}

//transactionsByStatusReport
export function transactionsByStatusReport(){
  return axios.post(`${appConfigs.api_url}transactions/status-report`);
}


//transactionsReport
export function transactionsReport(){
  return axios.post(`${appConfigs.api_url}transactions/transactions-report`);
}


//feesReport
export function feesReport(){
  return axios.post(`${appConfigs.api_url}transactions/fees-report`);
}






// UPDATE => POST: update the transaction on the server
export function updateTransaction(transaction) {
  return axios.post(`${appConfigs.api_url}transactions/update`, { transaction });
}

// UPDATE Status
export function updateStatusForTransactions(ids, status) {
  return axios.post(`${appConfigs.api_url}/updateStatusForTransactions`, {
    ids,
    status
  });
}

// DELETE => delete the transaction from the server
export function deleteTransaction(transactionId) {
  return axios.post(`${appConfigs.api_url}transactions/delete-transaction`, { transaction_id: transactionId });
}

// DELETE Transactions by ids
export function deleteTransactions(ids) {
  return axios.post(`${appConfigs.api_url}transactions/delete-transactions`, { transaction_ids: ids });
}
