import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {rolesSlice} from "../app/modules/UserManagement/_redux/roles/rolesSlice";
import {usersSlice} from "../app/modules/UserManagement/_redux/users/usersSlice";
import {conversionRatesSlice} from "../app/modules/Settings/_redux/conversionRates/conversionRatesSlice";
import {customersSlice} from "../app/modules/Settings/_redux/customers/customersSlice";
import {transactionsSlice} from "../app/modules/Transfers/_redux/transactions/transactionsSlice";
import {settingsSlice}from "../app/modules/Settings/_redux/system/settingsSlice";



export const rootReducer = combineReducers({
  auth: auth.reducer,
  roles: rolesSlice.reducer,
  users: usersSlice.reducer,
  settings: settingsSlice.reducer,
  conversionRates: conversionRatesSlice.reducer,
  customers: customersSlice.reducer,
  transactions: transactionsSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
