import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_money_transfer/layout";
import { DashboardPage } from "./pages/DashboardPage";

const UsersPage = lazy(() =>
  import("./modules/UserManagement/UserManagement")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);
const Settings = lazy(() =>
  import("./modules/Settings/Settings")
);
const Transactions = lazy(() =>
  import("./modules/Transfers/TransactionManagement")
);
export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (<>
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <Route path="/user-management" component={UsersPage} />
        <Route path="/profile" component={UserProfilepage} />
        <Route path="/settings" component={Settings} />
        <Route path="/conversion-rate" component={Settings} />
        <Route path="/customer-management" component={Settings} />
        <Route path="/transaction-management" component={Transactions} />
        <Redirect to="/error/err" />
      </Switch>
    </Suspense>
  </>);
}
